import LegalLayout from "../../components/legal/LegalLayout"
import React from "react"
import source from "raw-loader!../../constants/legal/taxes.md"

export default function TaxesPage() {
  return (
    <LegalLayout
      description="We are required to collect and remit sales tax in a number of jurisdictions."
      heading="Taxes!"
      source={source}
      title="Taxes"
    />
  )
}
